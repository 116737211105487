<template>
  <v-row>
    <v-col>
      <h5 class="text-h5">Redactar Examen</h5>
      <small class="text-secondary"
      >*Todos los campos son requeridos</small
      >
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "HeaderForm"
};
</script>

<style scoped>

</style>